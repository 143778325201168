import React from "react"

const CardBadge = (props) => {

    const {
        data
    } = props

    return (
        <span className="badge">{data || ''}</span>
    )
}

export default CardBadge